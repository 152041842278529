import { Alert, AlertTitle } from '@mui/material';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const FirebaseAlerts = ({banner, store}) => {

    const closeBannerAlert = () => {
       const bannerAlert = document.getElementById("banner_alert");
       bannerAlert.style.display = "none";
    }
    
    const closeSaleAlert = () => {
        const bannerAlert = document.getElementById("sale_alert");
        bannerAlert.style.display = "none";
        Cookies.set("sale_alert", "hide", {
            expires: 7,
        });
    }

    return (
        <>
        {banner && banner.active && (banner.title || banner.body) &&
            <Alert severity={banner.type} id='banner_alert' className='site-alert' onClose={closeBannerAlert}>
                <AlertTitle>{banner.title}</AlertTitle>
                <p>{banner.body}</p>
                {banner.link_url && banner.link_text &&
                <Link className='alert-link' to={banner.link_url}>
                    {banner.link_text}
                </Link>
                }
            </Alert>
        }
        {store && store.sale_active && (store.sale_title || store.sale_body) && !Cookies.get("sale_alert") &&
            <Alert severity="info" id='sale_alert' className='site-alert' onClose={closeSaleAlert}>
                <AlertTitle>{store.sale_title}</AlertTitle>
                <p>{store.sale_body}</p>
            </Alert>
        }
        </>
        
    )
}

export default FirebaseAlerts;
import { TailSpin, Comment } from 'react-loader-spinner';

export const Loader = () => {
    return (
        <div style={{ width: "100%" }}>
            <TailSpin
                visible={true}
                height="70"
                width="70"
                color="#1976d2"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="loader"
            />
        </div>
    )
}

export const ButtonLoader = () => {
    return (
        <div style={{ width: "100%" }}>
            <TailSpin
                visible={true}
                height="26"
                width="26"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="ButtonLoader"
            />
        </div>
    )
}

export const CommentLoader = () => {
    return (
        <div style={{ width: "100%", textAlign: "center" }}>
            <Comment
                visible={true}
                height="100"
                width="100"
                color="#fff"
                backgroundColor="rgba(18, 92, 198, 1)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="ButtonLoader"
            />
        </div>
    )
}

export default Loader;
